<template>
  <b-overlay
    :show="show"
    :fixed="fixed"
    :rounded="rounded"
    :variant="overlayVariant"
    :blur="blur"
    :opacity="opacity"
    :spinner-variant="spinnerVariant"
    :spinner-type="spinnerType"
  >
    <template #overlay>
      <div class="text-center">
        <b-spinner :variant="spinnerVariant" :type="spinnerType" class="m-2" />
        <p class="mt-1">{{ spinnerText }}</p>
      </div>
    </template>
    <slot></slot>
  </b-overlay>
</template>

<script>
import { computed } from "@vue/composition-api"
import useAppConfig from "@core/app-config/useAppConfig"

let $this

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "",
    },
    spinnerText: {
      type: String,
      default: "Cargando...",
    },
    blur: {
      type: String,
      default: "2px",
    },
    spinnerVariant: {
      type: String,
      default: "primary",
    },
    spinnerType: {
      type: String,
      default: "border",
    },
    opacity: {
      type: Number,
      default: 0.85,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const overlayVariant = computed(() => {
      return $this.variant ? $this.variant : skin.value
    })

    return {
      skin,
      overlayVariant,
    }
  },
  created() {
    $this = this
  },
}
</script>
